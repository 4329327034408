// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage'
import {getFirestore} from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyA55lI7GiXzM8KmQ__qcwhVhskH04TENHg",
//   authDomain: "arqsite-38058.firebaseapp.com",
//   projectId: "arqsite-38058",
//   storageBucket: "arqsite-38058.appspot.com",
//   messagingSenderId: "851763695236",
//   appId: "1:851763695236:web:4f95ada46113a98b007cd1",
//   measurementId: "G-9QMZSZP527"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBXppEYiZj8k3JhOIR81ZA2eUvbmiOKajc",
  authDomain: "organiarq-site.firebaseapp.com",
  projectId: "organiarq-site",
  storageBucket: "organiarq-site.appspot.com",
  messagingSenderId: "166412171746",
  appId: "1:166412171746:web:b8c59fadfe12a67a73e1bb",
  measurementId: "G-X6EVDXRT6F"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)

export const storage = getStorage(app)