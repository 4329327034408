import React from "react"
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import Swal from "sweetalert2";

const FormContato = () => {
    const captchaRef = React.useRef(null)
    // var recaptcha = document.forms["form-contato"]["g-recaptcha-response"];
    // recaptcha.required = true;

    function sendEmail(e) {
        e.preventDefault()
        axios.post('https://us-central1-organiarq-site.cloudfunctions.net/expressApi/email', {
            name: values.name,
            email: values.email,
            celular: values.celular,
            chegou: values.chegou,
            retorno: values.retorno,
            assunto: values.assunto,
            mensagem: values.mensagem
        })
            .then(res => {
                if (res.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Mensagem enviada com sucesso!',
                        text: 'Retornaremos o mais breve possível! Obrigada'
                    })

                    const input1 = document.getElementById('input1')
                    const input2 = document.getElementById('input2')
                    const input3 = document.getElementById('input3')
                    const input5 = document.getElementById('input5')
                    const select1 = document.getElementById('select1')
                    const select2 = document.getElementById('select2')
                    const select3 = document.getElementById('select3')
                    input1.value = ''
                    input2.value = ''
                    input3.value = ''
                    input5.value = ''
                    select1.value = ''
                    select2.value = ''
                    select3.value = ''

                }
            })
    }
    const [values, setValues] = React.useState();
    const onChange = (event) => {
        setValues({
            ...values, [event.target.name]:
                event.target.value
        });
    };

    return (
        <form id='form-contato' onSubmit={sendEmail} className="container-forms">
            <h1>Entre em contato conosco</h1>

            <div className="input-form">
                <input id="input1" required type="text" name="name" onChange={onChange} placeholder="Nome" />
                <input id="input2" required type="email" name="email" onChange={onChange} placeholder="Endereço de e-mail" />
                <input id="input3" required type="number" name="celular" onChange={onChange} placeholder="Celular" />
                <select id="select1" required name="chegou" onChange={onChange} >
                    <option value={''}>Como chegou até aqui?</option>
                    <option>Instagram</option>
                    <option>Facebook</option>
                    <option>Indicação</option>
                    <option>Google</option>
                    <option>Outro</option>
                </select>
                <select id="select2" required name="retorno" onChange={onChange} >
                    <option value={''}>Preferência de retorno</option>
                    <option>Whatsapp</option>
                    <option>Ligação</option>
                    <option>E-Mail</option>
                </select>
                
                <select id="select3" required name="assunto"  onChange={onChange} >
                    <option value={''}>Assunto</option>
                    <option>Reforma apartamento</option>
                    <option>Reforma casa</option>
                    <option>Projeto de decoração</option>
                    <option>Marcenaria personalizada</option>
                    <option>Consultoria (preciso de dicas)</option>
                    <option>Construção</option>
                    <option>Estudo da Viabilidade Financeira</option>
                    <option>Ainda não sei exatamente</option>
                </select>
                <textarea id="input5" required name="mensagem" onChange={onChange} placeholder="Conte-nos o que você busca" />
                <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                />
            </div>
            <button className="button-medio">Enviar</button>
        </form>
    )
}

export default FormContato