import "../projects.css";
import React from "react";
import {
    ref,
    getDownloadURL,
    uploadBytesResumable,
} from "firebase/storage";
import { storage, db } from "../base";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
// import { async } from "@firebase/util";
import Swal from "sweetalert2";
import logo from '../img/Prancheta 23@4x.png'
import { ReactComponent as Whatsapp } from '../img/whatsapp-svgrepo-com.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as Instagram } from '../img/instagram.svg';
import { ReactComponent as Pinterest } from '../img/pinterest-p.svg';
import { ReactComponent as Facebook } from '../img/facebook-f.svg';

const initialState = {
    title: '',
    discripition: '',
    antes: '',
    depois: '',
    local: '',
    comodos: [],
    principal: '',
    detalhes: ''
}

function AddProject() {
    const testeOnClick = async () => {

        const hamburgerMenu = await document.getElementById('menu')
        const overlay = document.querySelector("#overlay");
        const nav1 = document.querySelector("#nav-1");
        const nav2 = document.querySelector("#nav-2");
        const nav3 = document.querySelector("#nav-3");
        const nav4 = document.querySelector("#nav-4");
        const nav5 = document.querySelector("#nav-5");
        const navItems = await [nav1, nav2, nav3, nav4, nav5];

        // Control Navigation Animation
        function navAnimation(val1, val2) {
            navItems.forEach((nav, i) => {
                nav.classList.replace(`slide-${val1}-${i + 1}`, `slide-${val2}-${i + 1}`);
            });
        }

        function toggleNav() {
            // Toggle: Hamburger Open/Close
            hamburgerMenu.classList.toggle("active");

            //   Toggle: Menu Active
            overlay.classList.toggle("overlay-active");

            if (overlay.classList.contains("overlay-active")) {
                // Animate In - Overlay
                overlay.classList.replace("overlay-slide-left", "overlay-slide-right");

                // Animate In - Nav Items
                navAnimation("out", "in");
            } else {
                // Animate Out - Overlay
                overlay.classList.replace("overlay-slide-right", "overlay-slide-left");

                // Animate Out - Nav Items
                navAnimation("in", "out");
            }
        }
        toggleNav()

    }

    function scrolTop() {
        window.scrollTo(0, 0);
    }

    const [dupleImage, setDupleImage] = React.useState(false)

    const [data, setData] = React.useState(initialState);
    const [arrImgDuple, setArrImgDuple] = React.useState([])
    const [arrayEx, setArrayEx] = React.useState([])
    const { title, discripition, antes, depois, local, comodos, principal, detalhes } = data
    // const [file, setFile] = React.useState(null);
    const [progress, setProgress] = React.useState(null);
    // const [erros, setErros] = React.useState({})
    const [haveFile, setHaveFile] = React.useState([])
    const [listcomodo, setListComodo] = React.useState([])

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })

        setArrayEx([{ ...data, [e.target.name]: e.target.value }])
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        await addDoc(collection(db, "dbarq"), {
            ...data, timestamp: serverTimestamp()
        })

        // console.log(data)

        Swal.fire({
            icon: 'success',
            title: 'Projeto adicionado com sucesso',
            text: 'Você poderá visualizar esse projeto na área "Nossos projetos", na página inicial.'
        })

    }

    const handleChangeComodo = (e) => {

        comodos[listcomodo.length] = e.target.value

        setData({ ...data, comodos })

        setArrayEx([{ ...data, comodos }])
    }

    const teste = async (e) => {
        const id = e.target.id
        // console.log(id)

        const file = e.target.files[0]
        const uploadFile = () => {
            // const name = new Date().getTime() + file.name
            const storageRef = ref(storage, file.name)
            const uploadTask = uploadBytesResumable(storageRef, file)
            let timerInterval
            Swal.fire({
                title: 'Upload da imagem sendo feito',
                timer: 7000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('I was closed by the timer')
                }
            })

            uploadTask.on('state_changed', (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);

                switch (snapshot.state) {
                    case 'paused':
                        console.log('pause')
                        break;
                    case 'running':
                        console.log('running')
                        break;

                    default:
                        break;
                }
            }, (error) => {
                console.log(error)
            }, () => {

                if (dupleImage) {

                    if (arrImgDuple.length > 0) {
                        getDownloadURL(uploadTask.snapshot.ref).then((dowloadURL) => {
                            arrImgDuple.push(dowloadURL)
                            data[`img${haveFile.length + 1}`] = arrImgDuple
                            setArrayEx([{ ...data }])
                            setHaveFile((total) => {
                                return [...total, (haveFile.length + 1)]
                            })
                            setDupleImage(false)
                            setArrImgDuple([])
                            let toggle = document.getElementById('input-toggle')
                            toggle.checked = false
                            Swal.fire({
                                icon: 'success',
                                title: 'Foto adicionada!'
                            })
                        })
                    } else {
                        getDownloadURL(uploadTask.snapshot.ref).then((dowloadURL) => {
                            setArrImgDuple((total) => {
                                return [...total, dowloadURL]
                            })
                            Swal.fire({
                                icon: 'success',
                                title: 'Foto adicionada!'
                            })

                        })
                    }
                } else {
                    getDownloadURL(uploadTask.snapshot.ref).then((dowloadURL) => {
                        data[`img${haveFile.length + 1}`] = [dowloadURL]
                        setArrayEx([{ ...data }])
                        setHaveFile((total) => {
                            return [...total, (haveFile.length + 1)]
                        })
                        // console.log(data)
                        Swal.fire({
                            icon: 'success',
                            title: 'Foto adicionada!'
                        })
                    })
                }

            })
        }

        file && uploadFile()
    }
    React.useEffect(() => {
        if (!dupleImage) {
            let toggle = document.getElementById('input-toggle')
            toggle.checked = false
        }
    }, [dupleImage])

    const putInList = (e) => {
        e.preventDefault()
        let name = `img${listcomodo.length + 1}`


        setListComodo((data) => {
            return [
                ...data, <div>
                    <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                    <label style={dupleImage ? { display: 'none' } : { display: 'flex' }} className="label-add" for='foto-banner-add'>+ Adicionar foto cômodo</label>
                    <div className="content-foto-dois" style={dupleImage ? { display: 'flex' } : { display: 'none' }}>
                        <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label style={{ width: '49%' }} className="label-add" for='foto-banner-add'>+ Adicionar foto 1 cômodo</label>
                        <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label style={{ width: '49%' }} className="label-add" for='foto-banner-add'>+ Adicionar foto 2 cômodo</label>
                    </div>
                    <textarea className="input-to-write-discription" placeholder="Descrição da foto do cômodo" onChange={handleChangeComodo} />
                    <h2 className="AdicionadoAdd" style={Object.keys(data).includes(name) ? { display: 'flex' } : { display: 'none' }}>Foto adicionada</h2>
                </div>
            ]
        })

        // console.log(data)
    }

    function getimg(item, i) {

        let src = item[`img${7 + i}`]
        

        return src
    }

    React.useEffect(() => {
        const screen = document.getElementById('screen')

        if (arrayEx.length > 0) {
            screen.classList.add('show2')
        } else {
            screen.classList.remove('show2')
        }
    }, [, arrayEx])



    return (
        <body>
            <section className="screen-area-projetos3">

                <section style={arrayEx.length > 0 ? { display: 'none' } : { display: 'flex' }} className="add-project-background">
                    <Link to='/'><img src={logo} /></Link>
                    <h1 >Comece aqui a <br /> inserção do projeto.</h1>
                    <p>Essa parte será preenchida com o preview do projeto ao começar a cadastrar</p>

                    <a className="container-click-here" target='_blank' rel="noreferrer" href="https://api.whatsapp.com/send?phone=555511975284571">
                        <h6>Caso algo aconteça, clique aqui</h6>
                        <div className="my-telephone">
                            <Whatsapp className="whatsimage-add" />
                            <div>
                                <h4>Gabriel Viana</h4>
                                <h5>Mande um oi :)</h5>
                            </div>
                        </div>
                    </a>

                </section>
                <section id="screen" className="screen-view-projets2">
                    <div className='header2' style={arrayEx.length < 1 ? { display: 'none' } : { display: 'flex', backgroundColor: '#F3F3F2' }} id="header">
                        <Link to='/'> <img className="logo-header" src={logo} alt="logo organic arq em vermelho" /></Link>
                        <nav className="not-responsive">
                            <div className="navigation-header-page">
                                <ul>
                                    <li onClick={scrolTop}><Link to='/' id="a-header" className="a-header" >Home</Link></li>
                                    <li onClick={scrolTop}><Link to='/projetos' id="a-header1" className="a-header" >Projetos</Link></li>
                                    <li onClick={scrolTop}><Link to='/servicos' id="a-header2" className="a-header" >Serviços</Link></li>
                                    <li onClick={scrolTop}><Link to='/sobrenos' id="a-header3" className="a-header" >Sobre nós</Link></li>
                                    <li onClick={scrolTop}><Link to='/contato' id="a-header4" className="a-header" >Contato</Link></li>
                                    <li><a target='_blank' href='https://br.pinterest.com/organiarq/' id="a-header3" className="a-header" ><Pinterest className='rede-header2' /></a></li>
                                    <li><a target='_blank' href='https://www.facebook.com/organiarq' id="a-header3" className="a-header" ><Facebook className='rede-header3' /></a></li>
                                    <li><a target='_blank' href='https://www.instagram.com/organiarq/' id="a-header3" className="a-header" ><Instagram className='rede-header' /></a></li>
                                </ul>
                            </div>
                        </nav>

                        <div className="overlay overlay-slide-left responsive-header" id="overlay">
                            <nav>
                                <ul>
                                    <li id="nav-1" className="slide-out-1 center">
                                        <a href="/" className="center">Home</a>
                                    </li>
                                    <li id="nav-2" className="slide-out-2 center">
                                        <a href="/projetos" className="center">Projetos</a>
                                    </li>
                                    <li id="nav-3" className="slide-out-3 center">
                                        <a href="/servicos" className="center">Serviços</a>
                                    </li>
                                    <li id="nav-4" className="slide-out-4 center">
                                        <a href="/sobrenos" className="center">Sobre nós</a>
                                    </li>
                                    <li id="nav-5" className="slide-out-5 center">
                                        <a href="/contato" className="center">Contato</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div id="menu" onClick={testeOnClick} className="hamburger-menu" >
                            <div className="menu-bar1"></div>
                            <div className="menu-bar2"></div>
                            <div className="menu-bar3"></div>
                        </div>
                    </div>
                    {
                        arrayEx.map(item => {
                            return (
                                <section className="screen-area-projetos">

                                    <div className="estrutura-projeto">

                                        <div className="area-planta">
                                            <div className="text-planta-project">
                                                <h1>{item.title}</h1>
                                                <p>{item.discripition}</p>
                                                <h4>{item.local}</h4>
                                            </div>
                                        </div>

                                        <div className="area-antes-e-depois">
                                            <img src={item.img2} alt='planta imovel' />
                                            <div>
                                                <p>
                                                    {item.antes}
                                                </p>
                                            </div>

                                        </div>

                                        <div style={window.innerWidth < 900 ? { display: 'flex' } : { display: 'none' }} className="area-antes-e-depois">

                                            <img src={item.img3} alt='planta imovel' />
                                            <div>
                                                <p>
                                                    {item.depois}
                                                </p>
                                            </div>
                                        </div>

                                        <div style={window.innerWidth > 900 ? { display: 'flex' } : { display: 'none' }} className="area-antes-e-depois">

                                            <div>
                                                <p>
                                                    {item.depois}
                                                </p>
                                            </div>
                                            <img src={item.img3} alt='planta imovel' />
                                        </div>

                                        <img className="img-banner-project" src={item.img4} alt='' />
                                        <p className="paragrafo-principal">
                                            {item.principal}
                                        </p>

                                        <div className="imagens-projeto-principal">
                                            <div className="container-img-projeto-principal">
                                                <img src={item.img5} alt='' />
                                                <img src={item.img6} alt='' />
                                            </div>
                                            <p>
                                                {item.detalhes}
                                            </p>
                                            <br />
                                            <br />
                                            {
                                                item.comodos.map((comodo, i) => {
                                                    return (
                                                        <div className="content-comodo">
                                                            {
                                                                getimg(item, i).length > 1 ?
                                                                    <div className="duple-image-project">
                                                                        <img src={getimg(item, i)[0]} alt='imagem projeto' />
                                                                        <img src={getimg(item, i)[1]} alt='imagem projeto' />
                                                                    </div>
                                                                    :
                                                                    <img className="first-img-projeto" src={getimg(item, i)} alt='imagem projeto' />
                                                            }

                                                            <p>
                                                                {comodo}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>


                                    <div className="areabutton-servicos">
                                        <a href="/projetos"><h2>Voltar para projetos</h2></a>
                                    </div>
                                </section>
                            )
                        })

                    }
                </section>
                <form onSubmit={handleSubmit} style={arrayEx.length > 0 ? { width: '30%' } : { width: '54%' }} className="form-cadastro" >
                    <h1>Novo projeto</h1>
                    <div className="text-planta-project">
                        <div className="inputsiniciais">
                            <input className="input-to-write" name="title" placeholder="Título do projeto" onChange={handleChange} value={title} />
                            <input className="input-to-write" name="local" placeholder="Local | Ano" onChange={handleChange} value={local} />
                        </div>
                        <textarea className="input-to-write-discription" name="discripition" placeholder="Descrição do projeto" onChange={handleChange} value={discripition} />

                        <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label className="label-add" for='foto-banner-add' style={Object.keys(data).includes('img1') ? { display: 'none' } : { display: 'flex' }}>+ Adicionar foto 00</label>
                        <h2 className="AdicionadoAdd" style={Object.keys(data).includes('img1') ? { display: 'flex' } : { display: 'none' }}>Foto 00 adicionada</h2>

                        <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label className="label-add" for='foto-banner-add' style={Object.keys(data).includes('img2') ? { display: 'none' } : { display: 'flex' }}>+ Adicionar foto planta 1</label>
                        <h2 className="AdicionadoAdd" style={Object.keys(data).includes('img2') ? { display: 'flex' } : { display: 'none' }}>Foto planta 1 adicionada</h2>
                        <textarea className="textarea-to-write" name="antes" placeholder="Parágrafo para a parte 1." onChange={handleChange} value={antes} />

                        <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label className="label-add" for='foto-banner-add' style={Object.keys(data).includes('img2') && !Object.keys(data).includes('img3') ? { display: 'flex' } : { display: 'none' }}>+ Adicionar foto 1</label>
                        <h2 className="AdicionadoAdd" style={Object.keys(data).includes('img3') ? { display: 'flex' } : { display: 'none' }}>Foto 1 adicionada</h2>
                        <textarea className="textarea-to-write" name="depois" placeholder="Parágrafo para a parte 2." style={Object.keys(data).includes('img2') ? { display: 'flex' } : { display: 'none' }} onChange={handleChange} value={depois} />

                        <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label className="label-add" for='foto-banner-add' style={Object.keys(data).includes('img3') && !Object.keys(data).includes('img4') ? { display: 'flex' } : { display: 'none' }}>+ Adicionar foto 2</label>
                        <h2 className="AdicionadoAdd" style={Object.keys(data).includes('img4') ? { display: 'flex' } : { display: 'none' }}>Foto 2 adicionada</h2>
                        <textarea className="textarea-to-write" name="principal" placeholder="Parágrafo para a foto principal" style={Object.keys(data).includes('img3') ? { display: 'flex' } : { display: 'none' }} onChange={handleChange} value={principal} />

                        <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label className="label-add" for='foto-banner-add' style={Object.keys(data).includes('img4') && !Object.keys(data).includes('img5') ? { display: 'flex' } : { display: 'none' }}>+ Adicionar foto 3</label>
                        <h2 className="AdicionadoAdd" style={Object.keys(data).includes('img5') ? { display: 'flex' } : { display: 'none' }}>Foto 3 adicionada</h2>

                        <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label className="label-add" for='foto-banner-add' style={Object.keys(data).includes('img5') && !Object.keys(data).includes('img6') ? { display: 'flex' } : { display: 'none' }}>+ Adicionar foto 4</label>
                        <h2 className="AdicionadoAdd" style={Object.keys(data).includes('img6') ? { display: 'flex' } : { display: 'none' }}>Foto 4 adicionada</h2>

                        {/* <input className="input-add-file" type='file' id="foto-banner-add" onChange={(e) => teste(e)} />
                        <label className="label-add" for='foto-banner-add' style={Object.keys(data).includes('img6') && !Object.keys(data).includes('img7') ? { display: 'flex' } : { display: 'none' }}>+ Adicionar foto 5</label>
                        <h2 className="AdicionadoAdd" style={Object.keys(data).includes('img7') ? { display: 'flex' } : { display: 'none' }}>Foto 5 adicionada</h2> */}

                        <textarea className="textarea-to-write" name="detalhes" placeholder="Parágrafo para a descrição das fotos de detalhe" style={Object.keys(data).includes('img6') ? { display: 'flex' } : { display: 'none' }} onChange={handleChange} value={detalhes} />

                        {
                            listcomodo.map(value => {
                                return (
                                    value
                                )
                            })
                        }

                        <div className="content-toggle" style={Object.keys(data).includes('img6') ? { display: 'block' } : { display: 'none' }} >
                            <div className="toggle-user">
                                <label class="switch">
                                    <input type="checkbox" id="input-toggle" onChange={() => setDupleImage(!dupleImage)} />
                                    <span class="slider round"></span>
                                </label>
                                <button className="label-add button-comodo" onClick={putInList}>
                                    {dupleImage ? ' Clique para incluir detalhes de um cômodo com dupla imagem' : ' Clique para incluir detalhes de um cômodo'}
                                </button>
                            </div>
                            <h1>Dupla Imagem</h1>
                        </div>

                    </div>
                    <button className="button-forms" style={Object.keys(data).includes('img7') ? { display: 'flex' } : { display: 'none' }} disabled={progress !== null && progress < 100} type="submit">Adicionar Projeto</button>
                </form>
            </section>

            <section>

            </section>
        </body>
    );
}
export default AddProject;
