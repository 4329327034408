import "../App.css";
import React from "react";
import { db } from "../base";
import { collection, onSnapshot } from "firebase/firestore";
import { Link, useNavigate } from 'react-router-dom';
import { ElevateAppBar } from "../components/headers.tsx";
import Footer from "../components/Footer";
function Projects() {

    const [projects, setProjects] = React.useState([]);
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()

    // const [emblaRef] = useEmblaCarousel({ loop: false }, [Autoplay()])

    React.useEffect(() => {
        setLoading(true);
        const unsub = onSnapshot(collection(db, "dbarq"), (snapshot) => {
            let list = []
            snapshot.docs.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() })
            })
            setProjects(list)
            setLoading(false)
        }, (erro) => {
            console.log(erro)
        })

        return () => {
            unsub();
        }
    }, [])

    return (
        <body>

            <ElevateAppBar />

            <section id="projetos" className="area-projetos2">

                <h1 className="h1-body-sobrenos body-projects">Conheça nossos <br /> projetos</h1>

                <div className="container-projetos">
                    {
                        projects.reverse().map(value => {
                            return (
                                <div className="card-image-project">
                                    <Link to={`/project/${value.id}`}>
                                        <img src={value.img1} alt="imagem de um projeto" />
                                        <h1 className="saiba-mais-projetos2">{value.title}</h1>
                                        <div className="saiba-mais-projetos">
                                            <h2>{value.title}</h2> 
                                            <h6>{value.discripition}</h6>
                                            <h4>Saiba mais</h4>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }

                </div>

            </section>
            
            <Footer/>
        </body>

    );
}
export default Projects;
