import React from "react"
import { ReactComponent as Whatsapp } from '../img/whatsapp-svgrepo-com.svg';
import logoFooter from '../img/cinza tagline 1@3x.png'

const Footer = () => {

    return (
        <footer>
            <nav>
                <div className="logo-footer">
                    <img src={logoFooter} alt="logo organic arq em vermelho" />
                </div>
                <div className="contact-footer">
                    <h1>Todo sonho bem planejado pode se tornar realidade.</h1>
                    <h4 style={{ marginTop: 10 }}>Vamos criar um projeto incrível?</h4>
                    <a target='_blank' rel="noreferrer" href="https://api.whatsapp.com/send?phone=555511991470878">
                        <div>
                            <Whatsapp className="logo-instagram-footer" />
                            <p>Envie uma mensagem</p>
                        </div>
                    </a>
                </div>
            </nav>

            <h2>© Site desenvolvido por Gabriel Viana</h2>
        </footer>
    )
}

export default Footer