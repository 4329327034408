import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Projetos from "./pages/project";
import Home from "./pages/home";
import AddProject from "./pages/addProject";
import SobreNos from "./pages/sobreNos";
import Projects from "./pages/projects";
import Servicos from "./pages/servicos";
import Contato from "./pages/contato";
import WebMail from "./pages/webMail";


function App() {

  return (
    <Router>
      {/* <Header/> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Project/:id" element={<Projetos />} />
          <Route path="/newproject/add/organiarqwebsite/2022" element={<AddProject />} />
          <Route path="/SobreNos" element={<SobreNos />} />
          <Route path="/projetos" element={<Projects />} />
          <Route path="/servicos" element={<Servicos />} />
          <Route path="/contato" element={<Contato />} />
          <Route path="/webMail" element={<WebMail/>} />
        </Routes>
    </Router>
  )
}

export default App;
