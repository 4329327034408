import "../projects.css";
import React from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../base";
import { ElevateAppBar } from "../components/headers.tsx";
import { useParams } from 'react-router-dom';
import Footer from "../components/Footer";

function Project() {
  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  const param = useParams()

  React.useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(collection(db, "dbarq"), (snapshot) => {
      let list = []
      snapshot.docs.forEach((doc) => {
        if (doc.id === param.id) {
          list.push({ id: doc.id, ...doc.data() })
        }
      })
      setProjects(list)
      // console.log(list)
      setLoading(false)
    }, (erro) => {
      console.log(erro)
    })

    return () => {
      unsub();
    }

  }, [])

  function getimg(item, i) {

    let src = item[`img${7 + i}`]
    
    return src
  }


  return (
    <body>

      <ElevateAppBar/>
      {
        projects.map(item => {
          return (
            <section className="screen-area-projetos">
              <div className="estrutura-projeto">
                
                <div className="area-planta">
                  <div className="text-planta-project">
                    <h1>{item.title}</h1>
                    <p>{item.discripition}</p>
                    <h4>{item.local}</h4>
                  </div>
                </div>

                <div className="area-antes-e-depois">
                  <img src={item.img2[0]} alt='planta imovel' />
                  <div>
                    <p>
                      {item.antes}
                    </p>
                  </div>

                </div>

                <div style={window.innerWidth < 900 ? {display: 'flex'} : {display: 'none'}} className="area-antes-e-depois">

                  <img src={item.img3[0]} alt='planta imovel' />
                  <div>
                    <p>
                      {item.depois}
                    </p>
                  </div>
                </div>

                <div style={window.innerWidth > 900 ? {display: 'flex'} : {display: 'none'}} className="area-antes-e-depois">

                  <div>
                    <p>
                      {item.depois}
                    </p>
                  </div>
                  <img src={item.img3[0]} alt='planta imovel' />
                </div>

                <img className="img-banner-project" src={item.img4[0]} alt='foto principal do projeto' />
                <p className="paragrafo-principal">
                  {item.principal}
                </p>

                <div className="imagens-projeto-principal">
                  <div className="container-img-projeto-principal">
                    <img src={item.img5[0]} alt='imagem projeto' />
                    <img src={item.img6[0]} alt='imagem projeto' />
                  </div>
                  <p>
                    {item.detalhes}
                  </p>
                  <br />
                  <br />
                  {
                    item.comodos.map((comodo, i) => {
                      return (
                        <div className="content-comodo">
                          {
                            getimg(item, i).length > 1 ? 
                            <div className="duple-image-project">
                              <img src={getimg(item, i)[0]} alt='imagem projeto' />
                              <img src={getimg(item, i)[1]} alt='imagem projeto' />
                            </div>
                            : 
                            <img className= "first-img-projeto" src={getimg(item, i)} alt='imagem projeto' />
                          }
                          
                          <p>
                            {comodo}
                          </p>
                        </div>
                      )
                    })
                  }
                </div>
              </div>


              <div className="areabutton-servicos">
                <a href="/projetos"><h2>Voltar para projetos</h2></a>
              </div>
            </section>
          )
        })

      }

      <Footer/>
    </body>
  );
}
export default Project;
