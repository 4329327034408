import "../App.css";
import React from "react";
import projeto from '../img/Projetos.png'
import project from '../img/project.jpg'
import obra from '../img/obra.jpg'
import EVF from '../img/EVF.jpg'
import capacete from '../img/Gerenciamento de obras.png'
import consultoria from '../img/Consultoria.jpg'
import consultoriaIcon from '../img/Consultoria.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { ElevateAppBar } from "../components/headers.tsx";
import prancheta from '../img/Prancheta 68@3x (1).png'
import evf from '../img/EVF.png'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import FormContato from "../components/FomularioContato";
import Footer from "../components/Footer";

function Servicos() {

    return (
        <body>
            <ElevateAppBar />


            <section className="container-servicos" >
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    slidesPerView={1}
                    navigation
                    autoplay={{ delay: 35000 }}
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <div className="row-servicos-teste obras-serviços">
                            <div className="div-row-servicos2">
                                <img className="banner-servicos" src={project} alt='icon projeto' />
                                <img className="background-foto-servicos" src={prancheta} alt=' prancheta serviços' />
                            </div>
                            <div className="div-row-servicos">
                                <img src={projeto} alt='icon projeto' />
                                <h2>Projetos</h2>
                                <p>
                                    Um bom projeto é o caminho para materializar sonhos!
                                    <br />
                                    Vamos registrá-los no papel, aliando técnica, estética, funcionalidade e custo x benefício.
                                    <br />
                                    Seja para construção, reforma de interiores, mobiliário personalizado e/ou decoração, nossos projetos são desenvolvidos para serem úteis, facilitar a execução da obra e otimizar seu investimento.
                                    <br />
                                    <br />
                                    <ul>
                                        <li>Projetos para construção do zero</li>
                                        <li> Projetos para reformar um imóvel completo ou para apenas um ambiente </li>
                                        <li>Projetos de mobiliário personalizados com prévia de planejamento para organização</li>
                                        <li> Projetos decorativos</li>
                                        <li> Projetos de organização profissional</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="row-servicos-teste obras-serviços">
                            <div className="div-row-servicos2">
                                <img className="banner-servicos" src={obra} alt='icon projeto' />
                                <img className="background-foto-servicos" src={prancheta} alt=' prancheta serviços' />
                            </div>
                            <div className="div-row-servicos">
                                <img src={capacete} alt='icon projeto' />
                                <h2>Gerenciamento de obras</h2>
                                <p>
                                    Projeto tem que virar sonho materializado!
                                    <br />
                                    Sob nosso gerenciamento, faremos seu sonho virar realidade, sem dor de cabeça, custos e sustos desnecessários.
                                    <br />
                                    Do início ao fim, faremos seu sonho acontecer!
                                    <br />
                                    <br />
                                    <ul>
                                        <li>Gerenciamento de obras do zero </li>
                                        <li> Gerenciamento de reforma de um imóvel completo ou de um ambiente específico </li>
                                        <li>Gerenciamento de implementação de projeto decorativo </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="row-servicos-teste obras-serviços">
                            <div className="div-row-servicos2">
                                <img className="banner-servicos" src={consultoria} alt='icon projeto' />
                                <img className="background-foto-servicos" src={prancheta} alt=' prancheta serviços' />
                            </div>
                            <div className="div-row-servicos">
                                <img src={consultoriaIcon} alt='icon projeto' />
                                <h2>Consultorias</h2>
                                <p>
                                    Às vezes, uma ajuda pontual, uma opinião técnica ou um plano de ação é mais que o suficiente para o quê você tem em mente! Estamos preparadas para te atender com uma consultoria de forma simples!
                                    <br />
                                    <br />
                                    <ul>
                                        <li>Consultoria para recebimento de imóveis  </li>
                                        <li> Consultoria para decoração  </li>
                                        <li>Consultoria para soluções técnicas diversas </li>
                                        <li>E o que mais você precisar   </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="row-servicos-teste obras-serviços">
                            <div className="div-row-servicos2">
                                <img className="banner-servicos" src={EVF} alt='icon projeto' />
                                <img className="background-foto-servicos" src={prancheta} alt=' prancheta serviços' />
                            </div>
                            <div className="div-row-servicos">
                                <img src={evf} alt='icon projeto' />
                                <h2>Estudo de Viabilidade Financeira – EVF</h2>
                                <p>
                                    Quantas vezes você já ouviu a história de que a obra parou no meio do caminho por não ter mais verba disponível?
                                    <br />
                                    Sonho não pode virar pesadelo! Saber se seu sonho cabe no bolso ou planejá-lo para fazer caber é mais do que importante! É aqui que o nosso EVF pode te ajudar a tomar decisões, antes mesmo de ter um projeto completo!
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>

            <section className="form" id="contato">

                <FormContato />

            </section>

            <Footer />

        </body>

    );
}
export default Servicos;
