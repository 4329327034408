import "../App.css";
import React from "react";
import { ElevateAppBar } from "../components/headers.tsx";
import emailjs from '@emailjs/browser';
import FormContato from "../components/FomularioContato";
import Footer from "../components/Footer";

function Contato() {
    const form = React.useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cfxbova', 'template_jsfjjlh', form.current, 'H01N0ZxJ7oKhe97jo')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <body className="body-contato">
            <ElevateAppBar />

            <section className="form" id="contato">

                <FormContato/>

            </section>

            <Footer/>

        </body>

    );
}
export default Contato;
