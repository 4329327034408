import "../App.css";
import React from "react";
import { db } from "../base";
import banner1 from '../img/banner-1.png'
import banner2 from '../img/banner-2.png'
import banner3 from '../img/banner-3.png'
import banner6 from '../img/banner-6.png'
import fotoDonas from '../img/01.jpg'
import { collection, onSnapshot } from "firebase/firestore";
import projeto from '../img/Projetos cinza.png'
import EVF from '../img/EVF cinza.png'
import capacete from '../img/Prancheta 67@3x.png'
import consultoriaIcon from '../img/Prancheta 66@3x.png'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ElevateAppBar } from "../components/headers.tsx";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import FormContato from "../components/FomularioContato";
import Footer from "../components/Footer";


function Home() {

  const [projects, setProjects] = React.useState([]);

  React.useEffect(() => {
    AOS.init({ duration: 2000 });
    const unsub = onSnapshot(collection(db, "dbarq"), (snapshot) => {
      let list = []
      snapshot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() })
      })
      setProjects(list)
    }, (erro) => {
      console.log(erro)
    })

    return () => {
      unsub();
    }

  }, [])

  React.useEffect(() => {

    function hover() {
      let card1 = document.getElementById('outline1')
      let card2 = document.getElementById('outline2')
      let card3 = document.getElementById('outline3')
      let card4 = document.getElementById('outline4')
      let card = document.getElementById('card')

      var onLinkMouseEnter1 = function () {
        card1.classList.remove("onNavHover")
        card2.classList.add("onNavHover");
        card4.classList.add("onNavHover");
        card3.classList.add("onNavHover");
      }

      var onLinkMouseEnterDelay1 = function () {
        window.setTimeout(onLinkMouseEnter1, 1);
      }
      var onLinkMouseEnter4 = function () {
        card4.classList.remove("onNavHover")
        card2.classList.add("onNavHover");
        card3.classList.add("onNavHover");
        card1.classList.add("onNavHover");
      }

      var onLinkMouseEnterDelay4 = function () {
        window.setTimeout(onLinkMouseEnter4, 1);
      }

      var onLinkMouseEnter2 = function () {
        card1.classList.add("onNavHover");
        card4.classList.add("onNavHover");
        card2.classList.remove("onNavHover")
        card3.classList.add("onNavHover");
      }

      var onLinkMouseEnterDelay2 = function () {
        window.setTimeout(onLinkMouseEnter2, 1);
      }

      var onLinkMouseEnter3 = function () {
        card3.classList.remove("onNavHover")
        card2.classList.add("onNavHover");
        card4.classList.add("onNavHover");
        card1.classList.add("onNavHover");
      }

      var onLinkMouseEnterDelay3 = function () {
        window.setTimeout(onLinkMouseEnter3, 1);
      }


      var entercard = function () {
        card1.classList.remove("onNavHover");
        card4.classList.remove("onNavHover");
        card3.classList.remove("onNavHover");
        card2.classList.remove("onNavHover");
      }

      card1.onmouseenter = onLinkMouseEnterDelay1;
      card3.onmouseenter = onLinkMouseEnterDelay3;
      card4.onmouseenter = onLinkMouseEnterDelay4;
      card2.onmouseenter = onLinkMouseEnterDelay2;

      card.onmouseleave = entercard

    }

    hover()
  }, [])

  function scrolTop() {
    window.scrollTo(0, 0);
  }

  return (
    <body>
      <section id="inicio" className="banner-principal">
        <div className="carrossel-banner">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            slidesPerView={1}
            navigation
            autoplay={{ delay: 8000 }}
            pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <img src={banner6} alt='banner1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={banner1} alt='banner1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={banner2} alt='banner1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={banner3} alt='banner1' />
            </SwiperSlide>

          </Swiper>
        </div>

        <ElevateAppBar />

      </section>

      <section id="projetos" className="area-projetos">

        <h1>Projetos</h1>

        <div className="container-projetos">
          {
            projects.reverse().map((value, i) => {
              if (i < 3) {

                if (i % 2 === 0) {

                  return (
                    <Link to={`project/${value.id}`}>
                      <div data-aos='fade-right' className="card-project">
                        <img src={value.img4} alt="imagem de um projeto" />
                        {/* <h1 className="saiba-mais-projetos2">{value.title}</h1> */}
                        <div className="saiba-mais-projetos-home">
                          <h2>{value.title}</h2>
                          <p className="paragrafro-textos-padrao">{value.discripition}</p>
                          <h4>Saiba mais</h4>
                        </div>
                      </div>
                    </Link>
                  )
                } else {
                  return (
                    <Link to={`project/${value.id}`}>
                      <div data-aos='fade-left' className="card-project responsive-home-projetos">
                        <div className="saiba-mais-projetos-home">
                          <h2>{value.title}</h2>
                          <p className="paragrafro-textos-padrao">{value.discripition}</p>
                          <h4>Saiba mais</h4>
                        </div>
                        <img src={value.img4} alt="imagem de um projeto" />
                        {/* <h1 className="saiba-mais-projetos2">{value.title}</h1> */}
                      </div>
                      <div data-aos='fade-left' className="card-project responsive-home-projetos2">
                        <img src={value.img4} alt="imagem de um projeto" />
                        <div className="saiba-mais-projetos-home">
                          <h2>{value.title}</h2>
                          <p className="paragrafro-textos-padrao">{value.discripition}</p>
                          <h4>Saiba mais</h4>
                        </div>
                        {/* <h1 className="saiba-mais-projetos2">{value.title}</h1> */}
                      </div>
                    </Link>
                  )
                }
              }
            })
          }

          <div className="area-projetos-h2">
            <a href="/projetos"><h2>Ver todos os projetos</h2></a>
          </div>

          <div className="new-project">
            <button>Adicionar novo projeto +</button>
          </div>

        </div>

      </section>

      <section className="servicos-home">
        <h1>Como trabalhamos</h1>
        <div id="card" className="content-card-servicos">
          <div id="outline1" className="card-servicos outline1">
            <div className="div-row-servicos-home glassmorfism">
              <h2><img style={{ width: 30 }} src={capacete} alt='icon projeto' />Contato<br /> e conexão</h2>
              <p>Aqui você dá o primeiro passo para a materialização do seu sonho! Após entendermos suas necessidades e identificar o serviço que melhor lhe atenderá, faremos nossos combinados. Além disso, a sua participação será super importante.
              </p>
            </div>
          </div>
          <div id="outline2" className="card-servicos outline2">
            <div className="div-row-servicos-home glassmorfism">
              <h2><img style={{ width: 40 }} src={consultoriaIcon} alt='icon projeto' />Briefing e conceito</h2>
              <p>Nesta etapa, entenderemos sua rotina, gostos, histórias, e necessidades, e apresentaremos layouts, sugestões de materiais, paleta de cores e referências...
                <br />
                Tudo alinhado com o que você nos contou para medir se realmente entendemos o que você precisa.</p>
            </div>
          </div>
          <div id="outline3" className="card-servicos outline3">
            <div className="div-row-servicos-home glassmorfism">
              <h2 style={{ marginTop: '10%' }}><img style={{ width: 45 }} src={EVF} alt='icon projeto' />EVF</h2>
              <p>
                Aqui, traremos o Estudo de Viabilidade Financeira- orçamentos reais com médias do mercado e prazos de implementação, para que, com segurança, você possa tomar suas decisões alinhadas às suas disponibilidades financeiras.
              </p>
            </div>
          </div>
          <div id="outline4" className="card-servicos outline4">
            <div className="div-row-servicos-home glassmorfism">
              <h2><img style={{ width: 25 }} src={projeto} alt='icon projeto' /> Arquitetar!</h2>
              <p>Agora é conosco!
                <br />
                Com decisões tomadas, iremos representar seus sonhos tecnicamente.
                <br />
                Este material funcionará como "uma receita de bolo" para que profissionais habilitados possam executar no tempo que você definir pertinente.
              </p>
            </div>
          </div>

        </div>
        <h2 className="text-servicos-area">
          Agora que já temos tudo definido, é hora de materializar seus sonhos!
        </h2>
        <div className="areabutton-servicos">
          <a href="/servicos"><h2>Saiba mais sobre nossos serviços</h2></a>
        </div>
      </section>

      <section id="sobrenos" className="container-sobrenos">
        <div data-aos='fade-right' className="content-sobrenos">
          <img src={fotoDonas} alt="imagem de um projeto" />
          {/* <h1 className="saiba-mais-projetos2">{value.title}</h1> */}
          <div className="saiba-mais-projetos-home sobre-nos-home-texto">
            <h2>Sobre nós</h2>
            <p className="paragrafro-textos-padrao">Foi em 2016, a partir de uma oportunidade de reposicionamento de carreira, que a arquiteta Priscila Fulanetti se encheu de coragem e resolveu empreender. Logo, a talentosa amiga arquiteta Ana Novais, uniu-se a ela, e juntas fundaram a OrganiArq.
              <br />
              <br />
              No caminho, nem sempre há somente flores, mas o amor pelo ofício e a realização em ver cada cliente com seu objetivo alcançado, motiva seguir em frente.
              <br />
              <br />
              A OrganiArq é um sonho realizado, e sua essência preserva o desejo de tratar com o maior respeito o sonho de cada família e vibrar com ela diante de cada conquista alcançada! </p>
            <h4 onClick={scrolTop}><Link style={{ color: '#0D0D0D' }} to='sobrenos'>Saiba mais</Link></h4>
          </div>
        </div>
      </section>

      <section className="form" id="contato">

        <FormContato />

      </section>

      <Footer />
    </body>
  );
}
export default Home;
