import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import logo from '../img/Prancheta 23@4x.png'
import { Link } from 'react-router-dom';
import { ReactComponent as Instagram } from '../img/instagram.svg';
import { ReactComponent as Pinterest } from '../img/pinterest-p.svg';
import { ReactComponent as Facebook } from '../img/facebook-f.svg';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? "#F3F3F2" : "transparent",
      color: trigger ? "white" : "black",
      transition: trigger ? "0.3s" : "0.5s",
      boxShadow: "none",
    }
  });
}

const testeOnClick = async () => {

  const hamburgerMenu: any = await document.getElementById('menu')
  const overlay: any = document.querySelector("#overlay");
  const nav1 = document.querySelector("#nav-1");
  const nav2 = document.querySelector("#nav-2");
  const nav3 = document.querySelector("#nav-3");
  const nav4 = document.querySelector("#nav-4");
  const nav5 = document.querySelector("#nav-5");
  const navItems = await [nav1, nav2, nav3, nav4, nav5];

  // Control Navigation Animation
  function navAnimation(val1, val2) {
    navItems.forEach((nav: any, i) => {
      nav.classList.replace(`slide-${val1}-${i + 1}`, `slide-${val2}-${i + 1}`);
    });
  }

  function toggleNav() {
    // Toggle: Hamburger Open/Close
    hamburgerMenu.classList.toggle("active");

    //   Toggle: Menu Active
    overlay.classList.toggle("overlay-active");

    if (overlay.classList.contains("overlay-active")) {
      // Animate In - Overlay
      overlay.classList.replace("overlay-slide-left", "overlay-slide-right");

      // Animate In - Nav Items
      navAnimation("out", "in");
    } else {
      // Animate Out - Overlay
      overlay.classList.replace("overlay-slide-right", "overlay-slide-left");

      // Animate Out - Nav Items
      navAnimation("in", "out");
    }
  }
  toggleNav()

}

function scrolTop() {
  window.scrollTo(0, 0);
}

export const ElevateAppBar = (props: Props) => {
  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <div className='header' id="header">
          <Link to='/'> <img className="logo-header" src={logo} alt="logo organic arq em vermelho" /></Link>
          <nav className="not-responsive">
            <div className="navigation-header-page">
              <ul>
                <li onClick={scrolTop}><Link to='/' id="a-header" className="a-header" >Home</Link></li>
                <li onClick={scrolTop}><Link to='/projetos' id="a-header1" className="a-header" >Projetos</Link></li>
                <li onClick={scrolTop}><Link to='/servicos' id="a-header2" className="a-header" >Serviços</Link></li>
                <li onClick={scrolTop}><Link to='/sobrenos' id="a-header3" className="a-header" >Sobre nós</Link></li>
                <li onClick={scrolTop}><Link to='/contato' id="a-header4" className="a-header" >Contato</Link></li>
                <li><a target='_blank' href='https://br.pinterest.com/organiarq/' id="a-header3" className="a-header" ><Pinterest className='rede-header2' /></a></li>
                <li><a target='_blank' href='https://www.facebook.com/organiarq' id="a-header3" className="a-header" ><Facebook className='rede-header3' /></a></li>
                <li><a target='_blank' href='https://www.instagram.com/organiarq/' id="a-header3" className="a-header" ><Instagram className='rede-header' /></a></li>
              </ul>
            </div>

          </nav>

          <div className="overlay overlay-slide-left responsive-header" id="overlay">
            <nav>
              <ul>
                <li id="nav-1" className="slide-out-1 center">
                  <a href="/" className="center">Home</a>
                </li>
                <li id="nav-2" className="slide-out-2 center">
                  <a href="/projetos" className="center">Projetos</a>
                </li>
                <li id="nav-3" className="slide-out-3 center">
                  <a href="/servicos" className="center">Serviços</a>
                </li>
                <li id="nav-4" className="slide-out-4 center">
                  <a href="/sobrenos" className="center">Sobre nós</a>
                </li>
                <li id="nav-5" className="slide-out-5 center">
                  <a href="/contato" className="center">Contato</a>
                </li>
              </ul>
            </nav>
          </div>
          <div id="menu" onClick={testeOnClick} className="hamburger-menu" >
            <div className="menu-bar1"></div>
            <div className="menu-bar2"></div>
            <div className="menu-bar3"></div>
          </div>
        </div>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}