import "../sobreNos.css";
import React from "react";
import image1 from '../img/foto ana e priscila- horizontal.jpg';
import priscila from '../img/02.jpg';
import ana from '../img/03.jpg';
import { ElevateAppBar } from "../components/headers.tsx";
import maiara from '../img/04.jpg';
import carol from '../img/05.jpg';
import { ReactComponent as Instagram } from '../img/instagram.svg';
import { ReactComponent as Linkedin } from '../img/linkedin.svg';
import prancheta from '../img/Prancheta 68@3x (1).png';
import fotoHistoria from '../img/foto história.jpg';
import Footer from "../components/Footer";

function SobreNos() {

    return (
        <body>

            <section className="body-sobrenos">
                <ElevateAppBar />

                <h1 className="h1-body-sobrenos">Prazer, somos a <br /> OrganiArq</h1>

                <img className="banner-sobrenos-socia" src={image1} alt='imagem das sócias' />

                <section className="textos-page-sobrenos">

                    <p className="paragrafo-principal-sobrenos">
                        Arquitetura não é algo elitizado, tampouco inacessível.
                        <br />
                        É um direito de todos!
                        <br />
                        É viabilizar a concretização de sonhos e necessidades dentro das possibilidades de cada família.
                        <br />
                        É proporcionar bem-estar, funcionalidade e fazer valer o investimento.
                        <br />
                        É pensar cada espaço como único, personalizado para cada pessoa.
                        <br />
                        E fazê-lo complemento do que há dentro de cada indivíduo.
                        <br />
                        Quando projetamos, emprestamos nossas habilidades técnicas para traduzir desejos em possibilidades.
                        <br />
                        Acreditamos que todo sonho bem planejado pode se tornar realidade.

                    <h2 className="frase-sobrenos-negrito">
                        E reconhecemos a capacidade de cada um materializar o seu!
                    </h2>
                    </p>

                    <div className="content-apresentação">
                        <div className="div-row-servicos3">
                            <img className="banner-servicos2" src={fotoHistoria} alt='icon projeto' />
                            <img className="background-foto-servicos2" src={prancheta} alt=' prancheta serviços' />
                        </div>

                        <p className="paragrafo-menor-sobre">
                            Foi em 2016, a partir de uma oportunidade de reposicionamento de carreira, que a arquiteta Priscila Fulanetti se encheu de coragem e resolveu empreender. Logo, a talentosa amiga arquiteta Ana Novais, uniu-se a ela, e juntas fundaram a OrganiArq.
                            <br />
                            No caminho, nem sempre há somente flores, mas o amor pelo ofício e a realização em ver cada cliente com seu objetivo alcançado, motiva seguir em frente.
                            <br />
                            A OrganiArq é um sonho realizado, e sua essência preserva o desejo de tratar com o maior respeito o sonho de cada família e vibrar com ela diante de cada conquista alcançada!
                        </p>
                    </div>
                </section>

                <h1 className="title-tambem-fazem">Conheça nossa equipe </h1>

                <section className="container-sobrenos-pagesobre">
                    <div className="content-sobrenos-pagesobre">
                        <div className="content-page-sobrenos-div">
                            <img src={priscila} alt="foto da priscila" />

                            <article>
                                <h1>Priscila Fulanetti</h1>
                                <p>Sócia</p>
                                <div className="redes-sociais">
                                    <a target='_blank' href='https://www.linkedin.com/in/priscilafulanetti/'><Linkedin className="rede-header5" /></a>
                                    <a target='_blank' href='https://www.instagram.com/priscila_fulanetti/'> <Instagram className='rede-header4' /></a>
                                </div>
                                <p className="paragrafo-italic">“Arquitetura é a concretização de sonhos com possibilidades que superam as expectativas.”</p>

                                <p className="paragrafro-textos-padrao" id="texto-sobrenos">
                                    Priscila atua na arquitetura desde 2003, quando era estagiária, formando-se em 2004. Antes disso, já trabalhava como projetista de mobiliário para lojas de varejo numa metalúrgica e cursou Técnico em Decoração antes de ingressar na faculdade de Arquitetura e Urbanismo. Já atuou na área institucional, manutenção predial e incorporação imobiliária. Tem pós-graduação em Gestão de Projetos e Negócios Imobiliários, além de especialização em Organização Profissional.</p>
                            </article>
                        </div>
                        <div className="content-page-sobrenos-div not-responsive-contasiner-sobre">
                            <article>
                                <h1>Ana Novais</h1>
                                <p>Sócia</p>
                                <div className="redes-sociais">
                                    <a target='_blank' href='https://www.linkedin.com/in/ana-cristina-novais-3007834b/'><Linkedin className="rede-header5" /></a>
                                    <a target='_blank' href='https://www.instagram.com/anacris_novais/'> <Instagram className='rede-header4' /></a>
                                </div>
                                <p className="paragrafo-italic">“Arquitetura é entender as necessidades das pessoas e traduzi-las em traços.” </p>

                                <p className="paragrafro-textos-padrao" id="texto-sobrenos">
                                    Ana formou-se em 1997, e na graduação estagiou em escritórios de design de interiores, residenciais e especializados em restaurantes e bistrôs, e já atuou em diferentes frentes da arquitetura, como projetos voltados para empreendimentos educacionais e incorporação imobiliária, cujo desenvolvimento é desde a elaboração do produto residencial, conceito, até a entrega final do empreendimento e apartamento para moradia; além de ter cursado Técnico em Desenho de Comunicação e pós-graduação em Marketing. </p>
                            </article>
                            <img src={ana} alt="foto da priscila" />

                        </div>
                        <div className="content-page-sobrenos-div responsive-contsiner-sobre">
                            <img src={ana} alt="foto da priscila" />
                            <article>
                                <h1>Ana Novais</h1>
                                <p>Sócia</p>
                                <div className="redes-sociais">
                                    <a target='_blank' href='https://www.linkedin.com/in/ana-cristina-novais-3007834b/'><Linkedin className="rede-header5" /></a>
                                    <a target='_blank' href='https://www.instagram.com/anacris_novais/'> <Instagram className='rede-header4' /></a>
                                </div>
                                <p>“Arquitetura é entender as necessidades das pessoas e traduzi-las em traços.” </p>

                                <p className="paragrafro-textos-padrao" id="texto-sobrenos">
                                    Ana formou-se em 1997, e na graduação estagiou em escritórios de design de interiores, residenciais e especializados em restaurantes e bistrôs, e já atuou em diferentes frentes da arquitetura, como projetos voltados para empreendimentos educacionais e incorporação imobiliária, cujo desenvolvimento é desde a elaboração do produto residencial, conceito, até a entrega final do empreendimento e apartamento para moradia; além de ter cursado Técnico em Desenho de Comunicação e pós-graduação em Marketing. </p>
                            </article>

                        </div>
                    </div>
                </section>

                <h1 className="title-tambem-fazem">Também fazem parte da OrganiArq:</h1>
                <section className="container-fazemparte">
                    <div className="content-fazemparte">
                        <img src={maiara} alt='maiara souza' />
                        <h2>Mayara Oliveira</h2>
                        <h6>Designer de Interiores</h6>
                        <div className="redes-sociais">
                            <a target='_blank' href='https://www.linkedin.com/in/mayaraoliveira31'><Linkedin className="rede-header5" /></a>
                        </div>
                        <p>“Arquitetura é a arte de concretizar os sonhos das pessoas.”</p>

                    </div>
                    <div className="content-fazemparte">
                        <img src={carol} alt='maiara souza' />
                        <h2>Carolina Moutinho </h2>
                        <h6>Social Media </h6>
                        <div className="redes-sociais">
                            <a target='_blank' href='https://www.linkedin.com/in/carolina-moutinho-709142203/'><Linkedin className="rede-header5" /></a>
                            <a target='_blank' href='https://www.instagram.com/canmoutinho/'> <Instagram className='rede-header4' /></a>
                        </div>
                        <p>“Arquitetura é a forma de comunicação dos espaços.” </p>

                    </div>
                </section>
            </section>

            <Footer/>

        </body>

    );
}
export default SobreNos;
